import React from 'react'
import { first } from 'lodash'
import { typeface } from '../theme/'
import { Box, Text, Flex } from 'rebass'
import { graphql, Link } from 'gatsby'
import styled from 'styled-components'
import Layout from '../components/Layout'
import LogoLittleRocket from '../vectors/LogoLittleRocket'

const Container = styled(Flex)`
  align-items: center;
  min-height: 100vh;
  width: 100%;
  text-align: center;
  color: white;

  & ul {
    position: relative;
    z-index: 1;
    padding: 0;
    margin: 0;
    font-size: 1.1rem;
  }

  & li {
    display: inline-block;
    list-style: none;
    margin: 0 10px;
    padding: 0;
  }

  p {
    position: relative;
    z-index: 1;
    margin: 0.5rem;
    padding: 0;
  }
`

const Header = styled(Text)`
  position: relative;
  font-weight: bold;
  font-family: ${typeface.sans};
  line-height: 1.1;
  letter-spacing: 0.062em;
  z-index: 1;
  text-align: center;
  max-width: 450px;
`

export const LittleRocketLogo = styled(LogoLittleRocket)`
  margin-right: 0.25em;
  margin-bottom: 0.7em;
  transform: scale(1.2);
}
`

class NotFoundPage extends React.Component {
  render () {
    return (
      <Layout>
        <Container>
          <Box mx='auto' mt={'-10%'}>
            <Header is='h1' fontSize={[6]} mx='auto' mb={[10, null, 20]}>
              <LittleRocketLogo /><br />
                Drats! The link is broken or the page has been moved
            </Header>
            <p>Try one of these pages instead:</p>
            <ul>
              <li><Link to='/'>Home</Link></li>
              {
                first(this.props.data.allWordpressWpApiMenusMenusItems.edges).node.items.map((menuitem) => {
                  return <li><Link to={menuitem.url} key={`mi:${menuitem.title}`}>{menuitem.title}</Link></li>
                })
              }
            </ul>
          </Box>
        </Container>
      </Layout>
    )
  }
}

export default NotFoundPage

export const pageQuery = graphql`
  query errorPageQuery {
    allWordpressWpApiMenusMenusItems(
      filter: { name: { regex: "/Router/" } },
      sort: { fields: [name], order: DESC}
    ) {
      edges {
        node {
          id
          name
          items {
            title
            url
            type
          }
        }
      }
    }
  }
`
